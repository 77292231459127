import * as CryptoJS from 'crypto-js';
import {DatePipe} from "@angular/common";
import {GlobalConstant} from "../const/global-constants";
import * as moment from "moment-timezone";

export function encrypt(value:any) {
    if(value){
        let key =  GlobalConstant.aes_seceret.trim();
        let iv =  GlobalConstant.aes_iv.trim();
        let cipher = CryptoJS.AES.encrypt(value, CryptoJS.enc.Utf8.parse(key), {
            iv: CryptoJS.enc.Utf8.parse(iv),
            mode: CryptoJS.mode.CBC
        })
        return cipher.toString();
    }
    else{
        return null;
    }
}

export function decrypt(value:any) {
    if(value){
        let key =  GlobalConstant.aes_seceret.trim();
        let iv =  GlobalConstant.aes_iv.trim();
        try{
            let decrypt = CryptoJS.AES.decrypt(value, CryptoJS.enc.Utf8.parse(key), {
                iv: CryptoJS.enc.Utf8.parse(iv),
                mode: CryptoJS.mode.CBC
            })
            return decrypt.toString(CryptoJS.enc.Utf8);
        }
        catch(e){
            return null;
        }
    }
    return null;
}

export function getFormattedDate(date: string | Date, timezone: null | string = null){
    if(!date){
        return null;
    }
    if(timezone){
        return moment.tz(date, timezone).format('MMM DD, YYYY');
    }
    let datePipe = new DatePipe('en-US');
    return datePipe.transform(date, getDateFormat());
}

export function getFormattedDateTime(date: string | Date){
    let datePipe = new DatePipe('en-US');
    return datePipe.transform(date, getDateTimeFormat())
}

export function getDateFormat(){
    return "MMM dd, yyyy";
}

export function getDateTimeFormat(){
    return getDateFormat() + ' hh:mm a';
}

export function getFormattedMonth(date: string | Date, timezone: null | string = null){
    if(timezone){
        return moment.tz(date, timezone).format('MMM');
    }
    let datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'MMM')
}

export function getResourceUrl(path: string){
    return GlobalConstant.resourseURL + path;
}

export function scrollTop(top:number = 0): void{
    window.scroll({
        top: top,
        left: 0,
        behavior: 'smooth'
    });
}

export function timeSince(date:Date) {
    let seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
    let interval = seconds / 31536000;

    let val;
    if (interval > 1) {
        val = Math.floor(interval);
        return val + " year" + (val > 1 ? "s" : "");
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        val = Math.floor(interval);
        return val + " month" + (val > 1 ? "s" : "");
    }
    interval = seconds / 86400;
    if (interval > 1) {
        val = Math.floor(interval);
        return val + " day" + (val > 1 ? "s" : "");
    }
    interval = seconds / 3600;
    if (interval > 1) {
        val = Math.floor(interval);
        return val + " hour" + (val > 1 ? "s" : "");
    }
    interval = seconds / 60;
    if (interval > 1) {
        val = Math.floor(interval);
        return val + " minute" + (val > 1 ? "s" : "");
    }

    if(seconds <= 0){
        return "now";
    }
    return Math.floor(seconds) + " seconds";
}
export function digitValidate(ele) {
    ele.value = ele.value.replace(/[^0-9]/g, '');
    return ele;
};
